<template>
    <div v-if="voiceList !== undefined && voiceList.length > 0">
    <!-- media player -->
        <div v-show="downloadProgress === 100">
            <audio ref="audioPlayer" controls="controls" controlsList="nodownload">
                <source ref="audioPlayerSource" src="" />
                Your browser does not support the audio format.
            </audio>
        </div>
        <div class="loader-container" v-if="downloadProgress !== 100">
            <div class="loader-inner">
                <b-spinner type="grow" label="loading"></b-spinner>
                <span>{{ downloadProgress }}%</span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'

export default {
    props: ['voiceList', 'voiceKey'],
    data() {
        return {
            selectedVoiceIndex: 0,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            isLogin: localStorage.getItem('UserInfo') != null,
            downloadedBlob: {},
            downloadProgress: 0
        }
    },
    mounted() {
        if(this.voiceList !== undefined && this.voiceList.length > 0){
            this.setSelectedVoiceIndex(0)

            // block right click
            this.$refs.audioPlayer.addEventListener('contextmenu', event => event.preventDefault());
        }
    },
    beforeDestroy() {
        this.destroyPlayer()
    },
    methods: {
        async setSelectedVoiceIndex(index) {
            this.$refs.audioPlayer.pause()

            // re-init player
            const url = "https://api2.novelrealm.com/module/chapter&submodule=voice&token="+ this.voiceList[index].chapter_voice_token +"&chapter_id="+this.$route.params.chapter_id+"&chapter_voice_id="+this.voiceList[index].chapter_voice_id;
            if(url !== false){
                this.initPlayer(url, index);
            }
            else{
                console.log('Error file not found')
            }

            // update selected index
            this.selectedVoiceIndex = index;
        },
        async initPlayer(source, sourceIndex = 0) {
            /* eslint-disable no-console */

            let blob
            if(this.downloadedBlob[`${sourceIndex}`] === undefined){
                // download source
                const formData = new FormData();

                if (this.isLogin) {
                    formData.append('auth_memberid', this.userInfo.auth_memberid);
                    formData.append('auth_reference', this.userInfo.auth_token);
                }

                const response = await axios({
                    method: 'post',
                    url: source,
                    data: formData,
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(progressEvent.lengthComputable)
                        this.downloadProgress = percentCompleted;
                    }

                })

                this.downloadedBlob[`${sourceIndex}`] = response.data;

                blob = this.downloadedBlob[`${sourceIndex}`]
            }
            else{
                blob = this.downloadedBlob[`${sourceIndex}`]
            }

            this.$refs.audioPlayerSource.src = URL.createObjectURL(blob)

            this.$refs.audioPlayer.load()
        },
        destroyPlayer() {
            if (this.player) {
                this.player.dispose();
            }
        }
    }
}
</script>

<style scoped>
.list-group-item{
    color: black;
}
.darkmode .list-group-item{
    background-color: #35363A;
    color: white;
}

.list-group-item.active {
    background-color: #F5D087;
    border-color: white;
    color: white;
}
.voice-list-head {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-inner {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    width: auto;
}
</style>